import React from 'react';
import { Layout, Space } from 'antd';
import Notifications from 'components/Notifications';
import UserMenuDropdown from 'components/UserMenuDropdown';
import { BackButton } from './components';
import styles from './Header.module.scss';

export const HEADER_TITLE_ELEMENT_ID = 'header-portal-title';
export const HEADER_CONTENT_ELEMENT_ID = 'header-portal-content';

const Header = () => (
  <Layout.Header className={styles.header}>
    <Space size={16}>
      <BackButton />

      <h2 id={HEADER_TITLE_ELEMENT_ID} className={styles.title} />
    </Space>

    <Space size={24}>
      <div id={HEADER_CONTENT_ELEMENT_ID} />

      <Notifications />

      <UserMenuDropdown />
    </Space>
  </Layout.Header>
);

export default Header;
