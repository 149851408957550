import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import useWithdrawSubaccount from 'api/client/useWithdrawSubaccount';
import { IWallet } from 'interfaces';
import { Path } from 'routes/interfaces/Path';
import { useClients, useCurrencyRate, useTablePagination } from 'hooks';
import useTableColumns from 'hooks/useTableColumns';
import { useAuthorisationContext } from 'contexts';
import { unifyWalletsStructure } from 'utils';
import { LocalStorageKey } from 'utils/localStorage';
import getColumns from './utils/getColumns';
import { Pagination, transferFundsForm } from 'components';
import DraggableTable from 'components/DraggableTable';
import styles from './ClientsTab.module.scss';

export type ClientsTabProps = {
  unitId: number;
};

const ClientsTab = ({ unitId }: ClientsTabProps) => {
  const {
    checkPermissions,
    twoFA: {
      onSuccess,
      onError,
    },
  } = useAuthorisationContext();
  const { walletBalance } = useCurrencyRate();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { page, perPage, setPage, setPerPage } = useTablePagination({
    withoutURL: true,
  });

  const { data, isLoading, refetch, isRefetching } = useClients({
    paging: { limit: perPage, offset: (page - 1) * perPage },
    filter: { and: [{ unit: { id: { eq: unitId } } }] },
    sorting: [{ field: 'createdAt', direction: 'DESC' }],
  });

  useEffect(() => {
    refetch();
  }, [page, perPage, refetch]);

  const { mutate, isPending } = useWithdrawSubaccount({
    onSuccess: (res) => {
      onSuccess(res, () => {
        transferFundsForm.closeDrawer();
        refetch();
      });
    },
    onError: (error) => {
      onError(error, () => {
        notification.error({
          description: error.response?.data.message,
          message: error.response?.statusText,
        });
      });
    },
  });

  const clients = data?.nodes || [];
  const totalCount = data?.totalCount || 0;

  const handleOpenWithdrawModal = useCallback((wallets: IWallet[]) => {
    transferFundsForm.openDrawer({
      loading: isPending,
      onSubmit: mutate,
      wallets,
    });
  }, [isPending, mutate]);

  useEffect(() => {
    if (transferFundsForm.isOpened()) {
      transferFundsForm.updateDrawer({
        loading: isPending,
      });
    }
  }, [isPending]);

  const checkTotalWalletsBalance = useCallback((wallets: IWallet[]) => Number(
    walletBalance(unifyWalletsStructure(wallets), 'USDT'),
  ), [walletBalance]);

  const initialColumns = useMemo(() => getColumns({
    t,
    checkPermissions,
    checkTotalWalletsBalance,
    onWithdrawModalOpen: handleOpenWithdrawModal,
  }), [
    t,
    checkPermissions,
    checkTotalWalletsBalance,
    handleOpenWithdrawModal,
  ]);

  const { columns, setOrder } = useTableColumns({
    storageKey: LocalStorageKey.UNIT_CLIENTS_COLUMNS,
    initialColumns,
  });

  return (
    <div className={styles.wrapper}>
      <DraggableTable
        setOrder={setOrder}
        onRefresh={refetch}
        dataSource={clients}
        rowClassName={styles.rowClassName}
        rootClassName={styles.tableWrapper}
        loading={isLoading || isRefetching}
        columns={columns}
        scroll={{ x: 'max-content' }}
        onRow={record => ({
          onClick: () => navigate(`${Path.USERS_CLIENTS}/${record.uuid}`),
        })}
        pagination={false}
        rowKey="id"
      />
      <Pagination
        totalCount={totalCount}
        page={page}
        perPage={perPage}
        onPageChange={setPage}
        onPerPageChange={setPerPage}
        disablePageChange={isLoading || isRefetching || !totalCount}
        disablePerPageHandler={isLoading || isRefetching || !totalCount}
        elementsSize="xs"
      />
    </div>
  );
};

export default ClientsTab;
