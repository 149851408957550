import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import { format } from 'date-fns';
import { CopyOutlined } from '@ant-design/icons';
import styles from './ApiItem.module.scss';

export type ApiItemProps = {
  apiKey: string;
  expirationAt: string;
};

const ApiItem = ({ apiKey, expirationAt }: ApiItemProps) => {
  const { t } = useTranslation();

  const formattedDate = format(new Date(expirationAt), 'dd MMM yyyy, HH:mm');

  const onCopy = () => notification.success({ message: t('copied') });

  return (
    <div className={styles.wrapper}>
      <div className={styles.flex}>
        <div className={styles.label}>API {t('key')}</div>
        <div className={styles.date}>{t('business.expireOn')} {formattedDate}</div>
      </div>

      <div className={styles.flex}>
        <div className={styles.value}>{apiKey}</div>
        <CopyToClipboard text={apiKey} onCopy={onCopy}>
          <CopyOutlined style={{ fontSize: 16 }} />
        </CopyToClipboard>
      </div>
    </div>
  );
};

export default ApiItem;
