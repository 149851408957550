import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Permission } from '@fanckler/processing-auth';
import { Checkbox, Select, SelectProps, Skeleton, Switch } from 'antd';
import { useAuthorisationContext } from 'contexts';
import styles from './NotificationSettings.module.scss';

type NotificationsProps = {
  settings: Record<string, boolean>;
  isLoading?: boolean;
  onChange: (option: Record<string, boolean>) => void;
};

const categorizeSettings = (settings: Record<string, boolean>) => {
  return Object.keys(settings).reduce((acc, key) => {
    const category = key.split(/(?=[A-Z])/)[0].toLowerCase();

    if (!acc[category]) {
      acc[category] = [];
    }

    acc[category].push(key);
    return acc;
  }, {} as Record<string, string[]>);
};

const notificationTypes = ['email', 'push', 'telegram', 'sms'];

const getSelectedTypes = (settings: Record<string, boolean>) => {
  return Object.keys(settings).filter((key) => notificationTypes.includes(key) && settings[key]);
};

const alwaysDisabledOptions = ['sms'];

const options: SelectProps['options'] = [
  { label: 'Email', value: 'email' },
  { label: 'Push', value: 'push' },
  { label: 'Telegram (Bot)', value: 'telegram' },
  { label: 'Sms', value: 'sms' },
];

const NotificationSettings = ({
  settings,
  isLoading = false,
  onChange,
}: NotificationsProps) => {
  const { t } = useTranslation();
  const { checkPermissions } = useAuthorisationContext();
  const canUpdate = checkPermissions([Permission.ADMIN_IAM_USER_UPDATE]);

  const categorizedSettings = useMemo(() => categorizeSettings(settings), [settings]);
  const selectedTypes = useMemo(() => getSelectedTypes(settings), [settings]);

  const handleToggleNotificationType = (newTypes: string[]) => {
    const updated: Record<string, boolean> = {
      email: false,
      push: false,
      telegram: false,
      sms: false,
    };
    newTypes.forEach((type) => {
      updated[type] = true;
    });
    onChange(updated);
  };

  const handleToggle = (key: string) => (status: boolean) => {
    onChange({ [key]: status });
  };

  const renderSwitch = (key: string) => {
    return (
      <div className={styles.switchWrapper} key={key}>
        <span>{t(`notifications.types.${key}`)}</span>
        <Switch
          checked={settings[key]}
          onChange={handleToggle(key)}
          disabled={!canUpdate}
        />
      </div>
    );
  };
  return (
    <>
      <div className={styles.inline}>
        <p className={styles.title}>{t('notifications.notificationTypes')}</p>
        <Select
          mode="multiple"
          allowClear
          style={{ width: '60%' }}
          placeholder={t('notifications.notificationTypes')}
          defaultValue={['email']}
          value={selectedTypes}
          onChange={handleToggleNotificationType}
          className={styles.select}
          popupClassName={styles.selectPopup}
          disabled={!canUpdate}
        >
          {options && options.map((item) => (
            <Select.Option
              key={item.value}
              value={item.value}
              disabled={alwaysDisabledOptions.includes(item.value as string)}
            >
              <div className={styles.selectOption}>
                <Checkbox
                  checked={selectedTypes.includes(item.value as string)}
                  disabled={alwaysDisabledOptions.includes(item.value as string)}
                />
                {item.label}
              </div>
            </Select.Option>
          ))}
        </Select>
      </div>
      {isLoading && (
        <Skeleton />
      )}
      {!isLoading && (
        <>
          <div className={styles.card}>
            <span className={styles.title}>{t('notifications.categoriesTitle.privacySecurity')}</span>
            {categorizedSettings?.security?.map(renderSwitch)}
          </div>
          <div className={styles.card}>
            <span className={styles.title}>{t('notifications.categoriesTitle.finance')}</span>
            {categorizedSettings?.finance?.map(renderSwitch)}
          </div>
          <div className={styles.card}>
            <span className={styles.title}>{t('notifications.categoriesTitle.other')}</span>
            {categorizedSettings?.other?.map(renderSwitch)}
          </div>
        </>
      )}
    </>
  );
};

export default NotificationSettings;
