import React, { ReactElement, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, Space } from 'antd';
import { ColumnsType } from 'antd/es/table';
import clsx from 'clsx';
import { noop } from 'lodash';
import { FileExtension } from 'hooks/useExportFile';
import { SelectItem, SelectWindow } from '../SelectWindow';
import {
  DownOutlined,
  FileExcelOutlined,
  FileExclamationOutlined,
  RedoOutlined,
  ReloadOutlined,
  SlidersOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import styles from './ControlButtons.module.scss';

export type DisplayOf = {
  count: number;
  totalCount: number;
};

export type ControlButtonsProps<T> = {
  columns?: ColumnsType<T>;
  displayOf?: DisplayOf;
  prefixControls?: ReactElement | ReactElement[] | null;
  postfixControls?: ReactElement | ReactElement[] | null;
  updateDisplayedColumns?: (columnKeys: string[]) => void;
  onExportClick?: (type: FileExtension) => void;
  onRefreshClick?: () => Promise<void>;
  onAddFilterClick?: () => void;
  onResetFilterClick: () => void;
  hideResetFilterButton?: boolean;
  alwaysDisplayedColumns?: string[];
  displayedColumns?: string[];
  activatedFiltersCount?: number;
};

const ControlButtons = <T extends unknown>({
  columns,
  displayOf,
  onExportClick,
  onRefreshClick,
  prefixControls,
  postfixControls,
  displayedColumns,
  alwaysDisplayedColumns,
  onAddFilterClick,
  onResetFilterClick,
  hideResetFilterButton,
  updateDisplayedColumns,
  activatedFiltersCount,
}: ControlButtonsProps<T>): ReactElement => {
  const { t } = useTranslation();
  const [refreshLoading, setRefreshLoading] = useState(false);

  const handleExportClick = useCallback((type: FileExtension) => {
    return () => onExportClick ? onExportClick(type) : noop;
  }, [onExportClick]);

  const refreshClickHandler = useCallback(async () => {
    if (onRefreshClick) {
      try {
        setRefreshLoading(true);
        await onRefreshClick();
      } catch {
        // catch it silently
      } finally {
        setRefreshLoading(false);
      }
    }
  }, [onRefreshClick]);

  const onColumnSelect = useCallback((item: SelectItem, status: boolean) => {
    if (!displayedColumns || !alwaysDisplayedColumns || !updateDisplayedColumns) {
      return;
    }

    const columnsSet = new Set(displayedColumns);

    if (status) {
      columnsSet.add(item.key);
    } else {
      columnsSet.delete(item.key);
    }

    updateDisplayedColumns(Array.from(columnsSet));
  }, [alwaysDisplayedColumns, displayedColumns, updateDisplayedColumns]);

  return (
    <section className={styles.container}>
      <Space className={styles.column} size={15}>
        {prefixControls}
        {onAddFilterClick && (
          <Button
            onClick={onAddFilterClick}
            icon={<SlidersOutlined />}
            className={styles.button}
            size="large"
          >
            <span>{t('addFilter')}</span>
            {Boolean(activatedFiltersCount) && (
              <span className={styles.count}>{activatedFiltersCount}</span>
            )}
          </Button>
        )}
        {!hideResetFilterButton && (
          <Button
            className={clsx(styles.button, styles.resetFiltersButton)}
            onClick={onResetFilterClick}
            icon={<RedoOutlined />}
            size="large"
            danger
          >
            {t('resetFilter')}
          </Button>
        )}
        {columns && displayedColumns && updateDisplayedColumns && (
          <SelectWindow
            // items={columns.filter(({ key }) => Boolean(key)).map(({ key, title }) => ({
            items={columns.filter(col => !col.fixed).map(({ key, title }) => ({
              key: key as string,
              label: title as string,
              disabled: alwaysDisplayedColumns?.includes(key as string),
              selected: displayedColumns.includes(key as string),
            }))}
            onItemClick={onColumnSelect}
          >
            <Button
              className={clsx(styles.button, styles.columnsButton)}
              size="large"
            >
              <span>{t('columns')}</span>
              <DownOutlined />
            </Button>
          </SelectWindow>
        )}
        {postfixControls}
      </Space>
      <Space className={styles.column} size={25}>
        {displayOf && (
          <p className={styles.displayOf}>
            {`${t('display')} `}
            <b>{displayOf.totalCount === 0 ? 0 : displayOf.count}</b>
            {` ${t('of')} `}
            <b>{displayOf.totalCount}</b>
          </p>
        )}
        {onExportClick && (
          <Dropdown
            overlayClassName={styles.dropdown}
            menu={{
              items: [
                {
                  label: (
                    <span onClick={handleExportClick(FileExtension.XLSX)}>
                      <FileExcelOutlined />
                      {FileExtension.XLSX}
                    </span>
                  ),
                  key: '0',
                },
                {
                  label: (
                    <span onClick={handleExportClick(FileExtension.CSV)}>
                      <FileExcelOutlined />
                      {FileExtension.CSV}
                    </span>
                  ),
                  key: '1',
                },
                {
                  label: (
                    <span onClick={handleExportClick(FileExtension.JSON)}>
                      <FileExclamationOutlined />
                      {FileExtension.JSON}
                    </span>
                  ),
                  key: '3',
                },
              ],
            }}
            trigger={['click']}
          >
            <Button
              className={clsx(styles.button, styles.exportButton)}
              icon={<UploadOutlined />}
            />
          </Dropdown>
        )}
        {onRefreshClick && (
          <Button
            className={clsx(styles.button, styles.refreshButton)}
            icon={<ReloadOutlined />}
            disabled={refreshLoading}
            onClick={refreshClickHandler}
          />
        )}
      </Space>
    </section>
  );
};

export default ControlButtons;
