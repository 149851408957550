import React, { ReactElement, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { registerDrawer, SharedDrawerProps } from '@nuvalt/ui-kit';
import { Card } from 'antd';
import clsx from 'clsx';
import { noop } from 'lodash';
import { UnknownType } from 'types/Unknown';
import { MassWithdrawBodyType } from './hooks/types';
import { useUploadFile } from './hooks';
import { Button, Drawer, DrawerContent, DrawerFooter, DrawerHeader, With2FA } from 'components';
import { FilesList } from './components/FilesList';
import { PaymentOrderList } from './components/PaymentOrderList';
import { UploadFile } from './components/UploadFile';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import styles from './UploadPOForm.module.scss';

type POCreateType = {
  isLoading: boolean;
  onSubmit: (body: MassWithdrawBodyType) => void;
} & SharedDrawerProps;

const UploadPOForm = ({
  onSubmit,
  isLoading,
  closeDrawer = noop,
}: POCreateType): ReactElement => {
  const { t } = useTranslation();
  const {
    fileList,
    payments,
    onCloseModal,
    onUpload,
    onDrop,
    removeCurrentPO,
  } = useUploadFile(closeDrawer);

  const allowedPayments = useMemo(() => {
    return payments.filter(payment => payment?.walletValid && payment?.amountValid);
  }, [payments]);

  const onPOUpload = useCallback((otpCode?: string) => {
    onSubmit({
      otpCode,
      data: allowedPayments.map((item: UnknownType) => ({
        currency: item?.Currency?.toUpperCase(),
        payway: item?.Network?.toUpperCase(),
        walletAddress: item?.Wallet,
        amount: `${item?.Payout}`,
        category: 1,
        group: 1,
      })),
    });
  }, [allowedPayments, onSubmit]);

  const handleSubmitOtp = (otpCode: string) => {
    onPOUpload(otpCode);
  };

  return (
    <Drawer>
      <DrawerHeader title={t('processing.paymentOrders.massPayout')} onClose={closeDrawer}>
        <a
          download
          className={styles.example}
          href={`${process.env.PUBLIC_URL}/mass-po-example.xlsx`}
        >
          <span className={styles.text}>{t('downloadExample')}</span>
          <DownloadOutlined className={styles.icon} />
        </a>
      </DrawerHeader>
      <With2FA onSubmit={handleSubmitOtp} isDrawer>
        <DrawerContent>
          <div className={styles.createTask}>
            <div>
              {payments.length === 0 ? (
                <div style={{ height: 600 }}>
                  <UploadFile onUpload={onUpload} />
                </div>
              ) : (
                <Card className={clsx(styles.card, styles.payments)}>
                  <PaymentOrderList payments={payments} onDrop={removeCurrentPO} />
                  <div className={styles.footer}>
                    <span className={styles.info}>
                      {t('loaded')} <span>{allowedPayments.length} {t('of')} {payments.length}</span>
                    </span>
                  </div>
                </Card>
              )}
            </div>
            {fileList.length > 0 && (
              <Card className={clsx(styles.card, styles.fileList)} style={{ marginTop: 24 }}>
                <span className={styles.title}>{t('file.uploaded')}</span>
                <FilesList list={fileList} onDrop={onDrop} />
              </Card>
            )}
          </div>
        </DrawerContent>
        <DrawerFooter>
          {payments.length > 0 && (
            <Button
              loading={isLoading}
              disabled={allowedPayments.length === 0}
              onClick={() => onPOUpload()}
              suffixIcon={<UploadOutlined style={{ fontSize: 17 }} />}
            >
              {t('file.upload')}
            </Button>
          )}
          <Button
            block
            type="link"
            color="error"
            onClick={onCloseModal}
          >
            {t('cancel')}
          </Button>
        </DrawerFooter>
      </With2FA>
    </Drawer>
  );
};

export default registerDrawer(UploadPOForm, {
  id: 'uploadPOForm',
});
