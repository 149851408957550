import { useTranslation } from 'react-i18next';
import axios, { SERVICE } from 'libs/axios';
import { useMutation } from 'libs/reactQuery';
import { notification } from 'antd';
import { MutationOptions, SubWalletWithdrawDto } from './types';

const url = SERVICE.CLIENT + '/balance/withdraw/sub-wallet';

const useWithdrawSubaccount = (mutationOptions?: MutationOptions) => {
  const { t } = useTranslation();

  const withdrawSubaccount = async (body: SubWalletWithdrawDto) => {
    const { data } = await axios.post(url, body);
    return data;
  };

  return useMutation({
    ...mutationOptions,
    mutationFn: withdrawSubaccount,
    onSuccess(data, variables, context) {
      notification.success({ message: t('client.subaccountWithdrawSuccess') });

      if (mutationOptions?.onSuccess) {
        mutationOptions.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      notification.error({
        message: error.message,
        description: error.response?.data.message || t('somethingWrong'),
      });

      if (mutationOptions?.onError) {
        mutationOptions.onError(error, variables, context);
      }
    },
  });
};

export default useWithdrawSubaccount;
