import React, { useCallback, useContext, useId, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Permission } from '@fanckler/processing-auth';
import { Dropdown } from 'antd';
import { IWallet } from 'types/IWallet';
import { AuthorisationContext } from 'contexts';
import { Button } from 'components';
import { MoreOutlined, UploadOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons';
import styles from './ActionDropdown.module.scss';

enum KeysVariants {
  massPayout = '0',
  requestReplanishment = '2',
  addWallet = '3',
}

type ActionDropdownProps = {
  onMassPayout: () => void;
  onRequestReplanishment: () => void;
  disabled?: boolean;
  wallets: IWallet[];
  isLoading?: boolean;
};

const ActionDropdown = ({
  onRequestReplanishment,
  onMassPayout,
  disabled,
  wallets,
  isLoading,
}: ActionDropdownProps) => {
  const { t } = useTranslation();
  const buttonId = useId();
  const { checkPermissions } = useContext(AuthorisationContext);

  const isEmptyDropdown = useMemo(() => {
    return !checkPermissions([Permission.CLIENT_PAYMENT_ORDER_CREATE]) && !checkPermissions([Permission.CLIENT_REPLENISHMENT_REQUEST_CREATE]);
  }, [checkPermissions]);

  const items = useMemo(() => [
    {
      label: (
        <span><UploadOutlined />{t('processing.paymentOrders.massPayout')}</span>
      ),
      key: KeysVariants.massPayout,
      disabled: !checkPermissions([Permission.CLIENT_PAYMENT_ORDER_CREATE]),
    },
    {
      label: (
        <span><VerticalAlignBottomOutlined />{t('replanishment.requestReplanishment')}</span>
      ),
      key: KeysVariants.requestReplanishment,
      disabled: !checkPermissions([Permission.CLIENT_REPLENISHMENT_REQUEST_CREATE]),
    },
  ].filter(item => !item.disabled), [checkPermissions, t]);

  const handleClick = useCallback(({ key }: { key: string }) => {
    if (key === KeysVariants.massPayout) {
      onMassPayout();
    } else if (key === KeysVariants.requestReplanishment) {
      onRequestReplanishment();
    }
  }, [onMassPayout, onRequestReplanishment]);

  return (
    <Dropdown
      getPopupContainer={() => document.getElementById(buttonId) as HTMLElement}
      overlayClassName={styles.dropdown}
      menu={{
        items,
        onClick: handleClick,
      }}
      trigger={['click']}
      placement="bottomRight"
      arrow
      disabled={disabled || isEmptyDropdown || (!wallets.length && !isLoading)}
    >
      <Button
        id={buttonId}
        withoutWaveEffect
        withoutBgShadow
        type="link"
        suffixIcon={<MoreOutlined />}
      >
        {t('more')}
      </Button>
    </Dropdown>
  );
};

export default ActionDropdown;
