import { endOfDay } from 'date-fns';
import { TFunction } from 'i18next';
import { ALLOWED_EMAIL_SYMBOLS } from '../../../../constants/validation';
import {
  BetweenComparisonType,
  ComparisonType,
  FilterField,
  FilterFieldType,
  SelectOption,
} from '../../../../components/Filters';

const getFilterFields = (
  translate: TFunction,
  nameOptions?: SelectOption[],
): Map<string, FilterField> => new Map<string, FilterField>([
  ['name', {
    label: translate('name'),
    type: FilterFieldType.SELECT,
    field: 'name',
    alwaysVisible: true,
    comparisonType: ComparisonType.EQUAL,
    options: nameOptions || [],
  }],
  ['updatedBy.email', {
    label: translate('email'),
    type: FilterFieldType.TEXT,
    field: 'updatedBy.email',
    maxLength: 318,
    alwaysVisible: true,
    comparisonType: ComparisonType.I_LIKE,
    regex: ALLOWED_EMAIL_SYMBOLS,
  }],
  ['isActive', {
    label: translate('status'),
    type: FilterFieldType.SELECT,
    field: 'isActive',
    comparisonType: ComparisonType.IS,
    options: [
      { label: translate('activated'), value: true },
      { label: translate('deactivated'), value: false },
    ],
  }],
  ['createdAt.from', {
    label: `${translate('creationDate')} (${translate('dateFrom')})`,
    type: FilterFieldType.DATE,
    field: 'createdAt',
    maxDate: (filters) => (filters['createdAt.to'] ?? endOfDay(new Date())),
    betweenComparisonType: BetweenComparisonType.LOWER,
    comparisonType: ComparisonType.BETWEEN,
  }],
  ['createdAt.to', {
    label: `${translate('creationDate')} (${translate('dateTo')})`,
    type: FilterFieldType.DATE,
    field: 'createdAt',
    maxDate: () => endOfDay(new Date()),
    minDate: (filters) => (filters['createdAt.from']),
    betweenComparisonType: BetweenComparisonType.UPPER,
    comparisonType: ComparisonType.BETWEEN,
  }],
  ['updatedAt.from', {
    label: `${translate('updationDate')} (${translate('dateFrom')})`,
    type: FilterFieldType.DATE,
    field: 'updatedAt',
    maxDate: (filters) => (filters['updatedAt.to'] ?? endOfDay(new Date())),
    betweenComparisonType: BetweenComparisonType.LOWER,
    comparisonType: ComparisonType.BETWEEN,
  }],
  ['updatedAt.to', {
    label: `${translate('updationDate')} (${translate('dateTo')})`,
    type: FilterFieldType.DATE,
    field: 'updatedAt',
    maxDate: () => endOfDay(new Date()),
    minDate: (filters) => (filters['updatedAt.from']),
    betweenComparisonType: BetweenComparisonType.UPPER,
    comparisonType: ComparisonType.BETWEEN,
  }],
]);

export default getFilterFields;
