import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Path } from 'routes/interfaces/Path';
import { Button } from 'components/Button';
import { LineChartOutlined } from '@ant-design/icons';
import styles from './StatisticsButtonLink.module.scss';

export type StatisticsButtonLinkProps = {
  rootUnitId: number | string;
  unitId?: number | string;
};

const StatisticsButtonLink = ({ rootUnitId, unitId }: StatisticsButtonLinkProps) => {
  const { t } = useTranslation();

  const query = unitId ? `?unitId=${unitId}` : '';
  const url = `${Path.ADMINISTRATION_STATISTICS}/${rootUnitId}${query}`;

  return (
    <Link to={url} className={styles.link}>
      <Button
        type="default"
        className={styles.button}
        suffixIcon={<LineChartOutlined />}
      >
        {t('statistics.title')}
      </Button>
    </Link>
  );
};

export default StatisticsButtonLink;
