import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiDeleteBinLine, RiLockPasswordLine } from '@remixicon/react';
import { Form, FormInstance, Input, notification, Tabs } from 'antd';
import { Rule } from 'antd/es/form';
import { RcFile } from 'antd/lib/upload';
import { IUser } from 'interfaces/IUser';
import { UnknownType } from 'types/Unknown';
import { useDeleteAvatar, useUploadAvatar } from 'hooks/useAvatar';
import { useChangePassword } from 'components/UserAccount/hooks';
import { useAuthorisationContext } from 'contexts';
import { encryptPassword, LocalStorage, LocalStorageKey } from 'utils';
import { emailValidator } from 'utils/emailValidator';
import { Button, UploadLogo, UserAvatar } from 'components';
import { formChangePassword } from 'components/UserAccount/components';
import { ActiveSessionsTable, ActivityLogTable } from 'components/UserAccount/components/AccountActivity/components';
import styles from '../../FormEditMyAccount.module.scss';

type AccountProps = {
  user: IUser;
  onChangedData: (data: IUser, status: boolean) => void;
  form: FormInstance<IUser>
};

const Account = ({
  user,
  onChangedData,
  form,
}: AccountProps) => {
  const [initialAvatar, setInitialAvatar] = useState<string | null>(user?.avatar);
  const [newAvatar, setNewAvatar] = useState<RcFile>();
  const { mutate: uploadAvatar } = useUploadAvatar();
  const { mutate: deleteAvatar, isPending: isDeletePending } = useDeleteAvatar();
  const {
    twoFA: {
      onSuccess,
      onError,
    },
  } = useAuthorisationContext();

  const [passForm] = Form.useForm();
  const { t } = useTranslation();

  const inputEmailHandler = (e: ChangeEvent<HTMLInputElement>) => {
    form.setFieldsValue({
      email: e.target.value.trim(),
    });
  };

  const emailRules: Rule[] = [
    () => ({
      validator(_, value: string) {
        return emailValidator(t, value);
      },
    }),
  ];

  const handleChangeData = useCallback(() => {
    const formValue = form.getFieldsValue();
    const keys = Object.keys(formValue) as (keyof IUser)[];
    const isChanged = keys.some((key) => formValue[key] !== user[key]);
    onChangedData(formValue, isChanged);
  }, [form, onChangedData, user]);

  useEffect(() => {
    handleChangeData();
  }, [handleChangeData]);

  const { changePassword, isChangePasswordLoading } = useChangePassword({
    onSuccess: (response) => {
      onSuccess(response as UnknownType, () => {
        LocalStorage.set(LocalStorageKey.ACCESS_TOKEN, response.accessToken);

        formChangePassword.closeDrawer();
        passForm.resetFields();
        notification.success({
          message: t('myAccount.passwordChangedSuccessfully'),
        });
      });
    },
    onError: (error) => {
      onError(error as UnknownType, () => {
        const oldPassNotCorrectError = 'The old password is not correct';
        const oldAndNewPassSameError =
          'The new password must not be the same as the old one';

        if (error.response?.data.message === oldPassNotCorrectError) {
          passForm.setFields([
            {
              name: 'oldPassword',
              errors: [t('myAccount.validation.oldPasswordNotCorrect')],
            },
          ]);
        } else if (error.response?.data.message === oldAndNewPassSameError) {
          passForm.setFields([
            {
              name: 'newPassword',
              errors: [t('myAccount.validation.newPasswordRules')],
            },
          ]);
        } else {
          notification.error({
            message: error.response?.statusText,
            description: error.response?.data.message,
          });
        }
      });
    },
  });

  const onSubmitCreds = async ({ password, oldPassword, otpCode }: UnknownType) => {
    changePassword({
      oldPassword: await encryptPassword(oldPassword),
      password: await encryptPassword(password),
      otpCode,
    });
  };

  const handleOpenChangePassword = () => {
    formChangePassword.openDrawer({
      loading: isChangePasswordLoading,
      onSubmit: onSubmitCreds,
      form: passForm,
    });
  };

  useEffect(() => {
    if (formChangePassword.isOpened()) {
      formChangePassword.updateDrawer({
        loading: isChangePasswordLoading,
      });
    }
  }, [isChangePasswordLoading]);

  useEffect(() => {
    setInitialAvatar(user.avatar);
  }, [user]);

  const handleAvatarChange = (file: RcFile) => {
    setNewAvatar(file);
    uploadAvatar(file);
  };

  const handleAvatarDelete = () => {
    if (user.avatar) {
      deleteAvatar();
    }
    setInitialAvatar(null);
  };

  return (
    <>
      <div className={styles.avatarWrapper}>
        {user.avatar ? (
          <UserAvatar
            size={64}
            withoutText
            name={user.name}
            src={user.avatar}
          />
        ) : (
          <UploadLogo
            onChange={handleAvatarChange}
            value={newAvatar || initialAvatar}
          />
        )}
        <div className={styles.avatarContent}>
          <span className={styles.avatarTitle}>{t('myAccount.profileImage')}</span>
          <span className={styles.avatarDescription}>{t('myAccount.imageRule')}</span>
          {user.avatar && (
            <Button
              type="link"
              color="error"
              suffixIcon={<RiDeleteBinLine size={16} />}
              onClick={handleAvatarDelete}
              className={styles.avatarButton}
              disabled={isDeletePending}
            >
              {t('file.deleteAvatar')}
            </Button>
          )}
        </div>
      </div>
      <Form
        form={form}
        className={styles.form}
        initialValues={user}
        name="updatenternalUser"
        onChange={handleChangeData}
      >
        <div className={styles.inline}>
          <Form.Item
            name="name"
            label={t('nameAndSurname')}
            className={styles.formItem}
            rules={[
              { required: true, type: 'string', message: t('pleaseInputName') },
              { max: 100, message: t('emailRulesMax') },
              {
                pattern: /^([a-zA-Zа-яА-ЯҐЄІЇ-ґєії]+ [a-zA-Zа-яА-ЯҐЄІЇ-ґєії]+)$/,
                message: t('namePattern'),
              },
            ]}
          >
            <Input
              placeholder={t('nameAndSurname')}
              className={styles.input}
            />
          </Form.Item>
          <Form.Item
            required
            name="email"
            label={t('email')}
            className={styles.formItem}
            rules={emailRules}
          >
            <Input
              placeholder={t('email')}
              onChange={inputEmailHandler}
              className={styles.input}
            />
          </Form.Item>
        </div>
        <div style={{ display: 'flex' }} className={styles.item}>
          {user.rootUnit && (
            <div style={{ width: '100%' }}>
              <span className={styles.groupTitle}>
                {t('business.title')}:
              </span>
              <span className={styles.groupText}>
                {user.rootUnit?.name}
              </span>
            </div>
          )}
          <div style={{ width: '100%' }}>
            <span className={styles.groupTitle}>
              {t('users.roles.one')}:
            </span>
            <span className={styles.groupText}>
              {user.group.name}
            </span>
          </div>
        </div>
      </Form>
      <div className={styles.item}>
        <div className={styles.switchWrapper}>
          <span className={styles.groupText}>{t('password')}</span>
          <Button
            onClick={handleOpenChangePassword}
            type="default"
            className={styles.editButton}
            suffixIcon={<RiLockPasswordLine size={16} />}
            style={{ marginLeft: 'auto' }}
          >
            {t('myAccount.changePassword')}
          </Button>
        </div>
      </div>
      <Tabs
        defaultActiveKey={'activeUsersSessions'}
        type="card"
        tabBarStyle={{ paddingBottom: '16px' }}
        className={styles.tabs}
        items={[
          {
            key: 'activeUsersSessions',
            label: t('myAccount.activeUsersSessions'),
            children: <ActiveSessionsTable userId={user.id} />,
          },
          {
            key: 'activityLog',
            label: t('myAccount.activityLog'),
            children: <ActivityLogTable userId={user.id} />,
          },
        ]}
      />
    </>
  );
};

export default Account;
