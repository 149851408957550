import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import { Permission } from '@fanckler/processing-auth';
import { RiAddLine } from '@remixicon/react';
import clsx from 'clsx';
import _ from 'lodash';
import { UnknownType } from 'types/Unknown';
import { ITransactionCategory, ITransactionGroup, MutateActionEnum } from './api/types';
import { useModal } from 'components/Modal/hooks/useModal';
import { useAuthorisationContext } from 'contexts';
import useTransactionCategoryMutate from './api/category/useTransactionCategoryMutate';
import useGetTransactionGroups from './api/group/useGetTransactionGroups';
import useTransactionGroupMutate from './api/group/useTransactionGroupMutate';
import { ApproveActionModal, Button, Filters } from 'components';
import { HeaderPortal } from 'components/Header';
import { formCreateUpdate, GroupsTable } from './components';
import { FormDataResult } from './components/FormCreateUpdate';
import styles from './FinancialManagement.module.scss';

export enum ModalType {
  GROUP = 'group',
  CATEGORY = 'category',
}

const FinancialManagement = (): ReactElement => {
  const { user, checkPermissions } = useAuthorisationContext();
  const { t } = useTranslation();

  const {
    isOpen: isOpenApproveModal,
    payload: modalApprovePayload,
    onModalOpen: onModalApproveOpen,
    onModalClose: onModalApproveClose,
    onSetModalPayload: onSetModalApprovePayload,
  } = useModal('approveAction');

  const { data, refetch, isLoading } = useGetTransactionGroups({
    sorting: [{ field: 'createdAt', direction: 'ASC' }],
  });

  const { mutate: mutateGroup, isPending: isPendingGroup } = useTransactionGroupMutate({
    onSuccess: () => {
      refetch();
      formCreateUpdate.closeDrawer();
      onModalApproveClose();
    },
  });

  const { mutate: mutateCategory, isPending: isPendingCategory } = useTransactionCategoryMutate({
    onSuccess: () => {
      refetch();
      formCreateUpdate.closeDrawer();
      onModalApproveClose();
    },
  });

  const onDeleteGroup = (group: ITransactionGroup) => {
    batch(() => {
      onModalApproveOpen();
      onSetModalApprovePayload({ ...group, modalType: ModalType.GROUP });
    });
  };

  const onDeleteCategory = (category: ITransactionCategory) => {
    batch(() => {
      onModalApproveOpen();
      onSetModalApprovePayload({ ...category, modalType: ModalType.CATEGORY });
    });
  };

  const onDelete = (formData: FormDataResult) => {
    if (modalApprovePayload.modalType === ModalType.GROUP) {
      mutateGroup(MutateActionEnum.DELETE)({
        id: formData.id as number,
        name: formData.name as string,
      });
    } else {
      mutateCategory(MutateActionEnum.DELETE)({
        id: formData.id as number,
        name: formData.name as string,
      });
    }
  };

  const onCreateGroup = () => {
    formCreateUpdate.openDrawer({
      isEdit: false,
      onSubmit: (formData) => mutateGroup(MutateActionEnum.CREATE)({
        rootUnitId: formData.rootUnitId,
        name: formData.name?.trim() as string,
        color: formData.color as string,
        description: formData.description || '',
      }),
      initialValues: { modalType: ModalType.GROUP },
      loading: isPendingCategory || isPendingGroup,
      businessId: user?.rootUnitId,
      title: t('administration.financialManagement.createGroup'),
    });
  };

  const onEditGroup = (group: ITransactionGroup) => {
    formCreateUpdate.openDrawer({
      isEdit: true,
      onSubmit: (formData) => mutateGroup(MutateActionEnum.UPDATE)({
        id: formData.id,
        rootUnitId: formData.rootUnitId,
        name: formData.name?.trim() || group.name as string,
        ...(formData.color !== group.color && { color: formData.color }),
        ...(formData.description !== group.description && { description: formData.description }),
      }),
      initialValues: { ...group, modalType: ModalType.GROUP },
      onDeleteGroup,
      loading: isPendingCategory || isPendingGroup,
      businessId: group.rootUnitId || user?.rootUnitId,
      title: t('administration.financialManagement.editGroup'),
    });
  };

  const onCreateCategory = (group: ITransactionGroup) => {
    formCreateUpdate.openDrawer({
      isEdit: false,
      onSubmit: (formData) => mutateCategory(MutateActionEnum.CREATE)({
        unitIds: formData.unitIds,
        rootUnitId: formData.rootUnitId,
        groupId: formData.groupId as number,
        name: formData.name?.trim() as string,
        color: formData.color,
      }),
      initialValues: {
        groupId: group.id,
        rootUnitId: group.rootUnitId,
        modalType: ModalType.CATEGORY,
      },
      loading: isPendingCategory || isPendingGroup,
      businessId: group.rootUnitId || user?.rootUnitId,
      title: t('administration.financialManagement.createCategory'),
    });
  };
  const onEditCategory = (
    category: ITransactionCategory,
    rootUnitId: ITransactionGroup['rootUnitId'],
  ) => {
    formCreateUpdate.openDrawer({
      isEdit: true,
      onSubmit: (formData) => mutateCategory(MutateActionEnum.UPDATE)({
        id: formData.id,
        unitIds: formData.unitIds,
        rootUnitId: formData.rootUnitId,
        name: formData.name?.trim() || category.name as string,
        ...(formData.color !== category.color && { color: formData.color }),
      }),
      initialValues: {
        ...category,
        rootUnitId,
        units: _.map(category.units, 'id'),
        modalType: ModalType.CATEGORY,
      },
      onDeleteCategory,
      loading: isPendingCategory || isPendingGroup,
      businessId: rootUnitId || user?.rootUnitId,
      title: t('administration.financialManagement.editCategory'),
    });
  };

  const isGroup = modalApprovePayload.modalType === ModalType.GROUP;
  const modalActionTitleJSX =
    t(`administration.financialManagement.youSureWantDelete${isGroup ? 'Group' : 'Category'}`,
      { name: modalApprovePayload.name as string });

  const createGroupButton = (
    <Button
      onClick={onCreateGroup}
      suffixIcon={<RiAddLine />}
      style={{ padding: '7px 15px' }}
      disabled={!checkPermissions([Permission.CLIENT_TRANSACTION_GROUP_CREATE])}
    >
      {t('administration.financialManagement.createGroup')}
    </Button>
  );

  return (
    <>
      <HeaderPortal
        title={t('administration.financialManagement.title')}
        content={createGroupButton}
      />

      <Filters
        onRefresh={refetch as UnknownType}
        displayOf={{
          count: data?.length || 0,
          totalCount: data?.length || 0,
        }}
      />
      <div className={clsx(styles.layout, { [styles.loading]: isLoading })}>
        <GroupsTable
          groups={data || []}
          loading={isLoading}
          groupColHandlers={{
            onEditGroup,
            onCreateCategory,
          }}
          categoryColHandlers={{
            onEditCategory,
          }}
        />
      </div>
      <ApproveActionModal
        modalProps={{
          width: 480,
          destroyOnClose: true,
          isOpen: isOpenApproveModal,
          onClose: () => onModalApproveClose(false),
          afterClose: () => onModalApproveClose({}),
        }}
        formProps={{
          onApprove: onDelete,
          initialData: modalApprovePayload,
          cancelButtonText: t('no'),
          approveButtonText: t('yes'),
          actionText: modalActionTitleJSX,
          loading: isPendingCategory || isPendingGroup,
          onCancel: () => onModalApproveClose(false),
        }}
      />
    </>
  );
};

export default FinancialManagement;
