import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiUserAddLine } from '@remixicon/react';
import { Button, Divider, Input, Space, TreeSelect, Typography } from 'antd';
import clsx from 'clsx';
import { UnknownType } from 'types/Unknown';
import { IUnitTree, TreeNodeUnitType, TreeUnitsSelectData } from './types';
import { useUpdateEffect } from 'hooks';
import { flattenArray } from './utils';
import styles from './TreeUnitsSelect.module.scss';

type PropsChildren = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export type TreeUnitsSelectProps = {
  onSelect: (unitIds: number[]) => void;
  withSelectAllButton?: boolean;
  unitId?: string;
  data: IUnitTree[];
  isLoading: boolean;
  isMultiply?: boolean;
  openButtonTitle?: string;
  itemIndicatorType?: 'checkbox' | 'radio';
  dropdownAlign?: [number, number];
  children?: (props: PropsChildren) => ReactElement;
  initialSelectedValues?: (number | string)[];
  disableDeactivatedUnits?: boolean;
  selection?: boolean;
};

export const TreeUnitsSelect = ({
  onSelect,
  withSelectAllButton = true,
  unitId,
  data,
  isLoading,
  isMultiply = true,
  openButtonTitle = 'statistics.pickUnits',
  itemIndicatorType = 'checkbox',
  dropdownAlign = [-190, 0],
  children,
  initialSelectedValues,
  disableDeactivatedUnits,
  selection = true,
}: TreeUnitsSelectProps) => {
  const [selectedValues, setSelectedValues] = useState<TreeUnitsSelectData[]>([]);
  const [isAllSelected, setAllSelected] = useState<boolean>(false);
  const [isOpen, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const { t } = useTranslation();

  useUpdateEffect(() => {
    const selectedUnitsIds = selectedValues.map(item => item.value);
    onSelect(selectedUnitsIds);
  }, [onSelect, selectedValues]);

  const generateTreeNodes = useCallback((treeData: IUnitTree[]) => {
    return treeData.map(item => {
      const transformedItem: TreeNodeUnitType = {
        title: item.name,
        value: item.id,
        selectable: false,
        checked: selection
          ? (item.id === Number(unitId) || Boolean(initialSelectedValues?.includes(item.id)))
          : false,
        disabled: item.id === Number(unitId) || (disableDeactivatedUnits ? !item.isActive : false),
        key: item.id.toString(),
      };

      if (item.children && item.children.length > 0) {
        transformedItem.children = generateTreeNodes(item.children);
      }

      return transformedItem;
    });
  }, [initialSelectedValues, unitId]);

  const treeNodes = useMemo(() => generateTreeNodes(data || []), [data, generateTreeNodes]);

  useEffect(() => {
    if (treeNodes.length) {
      const defaultSelected: TreeUnitsSelectData[] = flattenArray(treeNodes).filter((node) => node.checked).map((node) => ({
        label: node.title,
        value: node.value,
      }));
      setSelectedValues(defaultSelected);
    }
  }, [treeNodes, isMultiply]);

  const onSelectAll = useCallback(
    (allData: TreeNodeUnitType[]) => {
      const allValues: TreeUnitsSelectData[] = [];

      const addValues = (nodes: TreeNodeUnitType[]) => {
        for (const node of nodes) {
          if (isAllSelected) {
            if (node.disabled) {
              allValues.push({
                label: node.title,
                value: node.value,
                disabled: node.disabled,
              });
            }
          } else {
            allValues.push({
              label: node.title,
              value: node.value,
              disabled: node.disabled,
            });
          }

          if (node.children) {
            addValues(node.children);
          }
        }
      };

      addValues(allData);
      setSelectedValues(allValues);
      setAllSelected(prevState => !prevState);
    }, [isAllSelected]);

  const filterTree = (inputValue: string, tree: UnknownType) => {
    if (tree.title?.toLowerCase()?.includes(inputValue.toLowerCase())) {
      return true;
    }
    if (tree.children && tree.children.length > 0) {
      for (const child of tree.children) {
        if (filterTree(inputValue, child)) {
          return true;
        }
      }
    }
    return false;
  };

  const dropdownRender = useCallback(
    (treeSelect: ReactElement) => (
      <div className={styles.dropdownContainer}>
        <div className={styles.header}>
          <Typography.Title className={styles.title}>{t('unitsCount')}</Typography.Title>
          {withSelectAllButton && (
            <Button
              type="link"
              onClick={() => onSelectAll(treeNodes)}
              className={styles.selectAllBtn}
            >
              {isAllSelected ? t('unselectAll') : t('selectAll')}
            </Button>
          )}
        </div>

        <Divider className={styles.divider} />

        <Space.Compact direction="vertical" className={styles.content}>
          <Input.Search
            onChange={e => setSearch(e.target.value)}
            placeholder={t('statistics.searchBusinessAndUnits')}
            className={styles.searchInput}
          />

          {treeSelect}
        </Space.Compact>
      </div>
    ), [t, withSelectAllButton, isAllSelected, onSelectAll, treeNodes]);

  return (
    <div className={styles.wrapper}>
      <TreeSelect
        open={isOpen}
        onDropdownVisibleChange={() => setOpen(!isOpen)}
        dropdownAlign={{ offset: dropdownAlign }}
        dropdownRender={dropdownRender}
        searchValue={search}
        filterTreeNode={filterTree}
        treeCheckable
        value={selectedValues}
        onSelect={(_, newValue) => {
          if (isMultiply) return;
          const { title: label, ...rest } = newValue;

          return setSelectedValues([{ ...rest, label }]);
        }}
        onChange={(newValues) => isMultiply && setSelectedValues(newValues)}
        treeCheckStrictly
        treeData={treeNodes}
        showCheckedStrategy={TreeSelect.SHOW_ALL}
        className={styles.input}
        popupClassName={clsx(styles.dropdown, {
          [styles.itemRadio]: itemIndicatorType === 'radio',
        })}
      />

      {children
        ? children({ isOpen, setIsOpen: setOpen })
        : (
          <Button
            type="link"
            loading={isLoading}
            disabled={isLoading}
            icon={<RiUserAddLine size={18} />}
            onClick={() => setOpen(!isOpen)}
            className={styles.pickUnitsBtn}
          >
            {t(openButtonTitle)}
          </Button>
        )
      }
    </div>
  );
};
