import en_GB from 'antd/lib/locale/en_GB';
import uk_UA from 'antd/lib/locale/uk_UA';
import enLocale from 'date-fns/locale/en-US';
import ukLocale from 'date-fns/locale/uk';
import { Language, LanguageLocales } from './types';
import enFlagImage from 'assets/images/flags/en.png';
import uaFlagImage from 'assets/images/flags/ua.png';

// Flags images: https://icons8.ru/icon/set/%D1%84%D0%BB%D0%B0%D0%B3%D0%B8/color

const locales: LanguageLocales = {
  [Language.UA]: {
    antdLocale: uk_UA,
    dateFnsLocale: ukLocale,
    image: uaFlagImage,
  },
  [Language.EN]: {
    antdLocale: en_GB,
    dateFnsLocale: enLocale,
    image: enFlagImage,
  },
};

export default locales;
