import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Permission } from '@fanckler/processing-auth';
import { AxiosError } from 'libs/axios';
import { cleverRefetchQueries } from 'libs/reactQuery';
import { notification } from 'antd';
import clsx from 'clsx';
import { NestedUnitType } from 'interfaces/IUnit';
import { UnknownType } from 'types/Unknown';
import { MassWithdrawBodyType } from 'components/UploadPOForm/hooks/types';
import { setReplenishmentRequest } from 'pages/Administration/Units/actions';
import { formWithdraw } from 'pages/Administration/Units/components/FormWithdraw';
import { WithdrawFormDataReturned } from 'pages/Administration/Units/components/FormWithdraw/FormWithdraw';
import { useCreatePayment } from 'pages/Administration/Units/hooks/useCreatePayment';
import { useRequestReplen } from 'pages/Administration/Units/hooks/useRequestReplen';
import { StageEnum } from 'hooks/use2FA';
import { useColdStorageHardwareId } from 'components/ColdStorage/hooks';
import { useMassWithdraw } from 'components/UploadPOForm/hooks';
import { useAuthorisationContext } from 'contexts';
import { Button, uploadPOForm } from 'components';
import { ActionDropdown } from './components/ActionDropdown';
import { PlusOutlined } from '@ant-design/icons';
import styles from './Actions.module.scss';

type ActionsProps = {
  disabled?: boolean;
  onRefresh: () => Promise<void>;
  unit: NestedUnitType | undefined
  isLoading?: boolean;
};

const Actions = ({
  unit,
  disabled,
  onRefresh,
  isLoading,
}: ActionsProps) => {
  const [formData, setFormData] = useState<WithdrawFormDataReturned | MassWithdrawBodyType>();
  const hardwareId = useColdStorageHardwareId();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    refreshUser,
    checkPermissions,
    twoFA: {
      onError,
      onSuccess,
      setPrevStage,
    },
  } = useAuthorisationContext();

  const {
    massWithdrawMutate,
    isMassWithdrawLoading,
  } = useMassWithdraw({
    onSuccess: async (response, variables) => {
      setFormData(variables.body);

      onSuccess(response, () => {
        uploadPOForm.closeDrawer();
        refreshUser();
        onRefresh();

        if (response.status === 'ERROR') {
          response.data.forEach(item => {
            notification.error({
              message: item.error,
              description: (
                <div className={styles.notifyDesc}>
                  <span className={styles.address}>{item.walletAddress}</span>

                  <div className={styles.amountPayway}>
                    <span className={styles.amount}>{item.amount}</span>
                    <span className={styles.payway}>{item.payway}</span>
                  </div>
                </div>
              ),
              duration: 10,
            });
          });
        } else {
          notification.success({
            message: t('processing.paymentOrders.allPaymentsCreated'),
          });
        }
      });
    },
    onError: async (error, variables) => {
      setFormData(variables.body);

      onError(error, () => {
        notification.error({
          description: error?.response?.data?.message,
          message: error?.response?.statusText,
        });
      });
    },
  });

  const { mutateCreatePayment, isCreating } = useCreatePayment({
    onSuccess: async (data) => {
      await cleverRefetchQueries('transactions');
      cleverRefetchQueries('unit-total-balances');
      setPrevStage(StageEnum.DEFAULT);

      onSuccess(data, () => {
        formWithdraw.closeDrawer();
        refreshUser();
        onRefresh();

        notification.success({
          message: t('processing.paymentOrders.paymentCreated'),
        });
      });
    },
    onError: async (data) => {
      onError(data, () => {
        notification.error({
          description: data?.response?.data?.message,
          message: data?.response?.statusText,
        });
      });
    },
  });

  const { mutateRequestReplen, isRequesting } = useRequestReplen({
    onSuccess: async () => {
      formWithdraw.closeDrawer();
      dispatch(setReplenishmentRequest(true));

      notification.success({
        message: t('replanishment.requestSent'),
      });
    },
    onError: (e: AxiosError) => {
      const { message, error } = e.response?.data as { message: string; error: string };
      notification.error({ message: error, description: message });
    },
  });

  const wallets = useMemo(() => unit?.wallets || [], [unit]);

  const createPayment = () => {
    const onSubmit = (data: WithdrawFormDataReturned) => {
      mutateCreatePayment({
        uuid: unit?.uuid as string,
        body: {
          hardwareId: hardwareId || undefined,
          group: data.group,
          feeValue: data.feeValue,
          totalFee: data.totalFee,
          gasPrice: data.gasPrice,
          currency: data.currency,
          amount: data.amount,
          otpCode: data.otpCode,
          category: data.category,
          annotation: data.annotation,
          walletAddress: data.walletAddress,
          payway: data.network === 'Bitcoin' ? 'BTC' : data.network,
        },
      });
    };

    formWithdraw.openDrawer({
      unitId: unit?.uuid || '',
      isWithdraw: true,
      onSubmit,
      isLoading: isCreating || isRequesting,
      wallets,
      initialValues: formData as UnknownType,
      title: t('processing.paymentOrders.createPayment'),
      rootUnitId: unit?.rootUnitId as UnknownType,
    });
  };

  const uploadPayments = () => {
    uploadPOForm.openDrawer({
      onSubmit: (body) => massWithdrawMutate({
        uuid: unit?.uuid as string,
        body,
      }),
      isLoading: isMassWithdrawLoading,
    });
  };

  const requestReplen = () => {
    const onSubmit = (data: WithdrawFormDataReturned) => {
      mutateRequestReplen({
        body: {
          unitId: unit?.id as number,
          group: data.group,
          network: data.network,
          currency: data.currency,
          category: data.category,
          amount: Number(data.amount),
          description: data.description,
          walletAddress: data.walletAddress,
        },
      });
    };

    formWithdraw.openDrawer({
      unitId: unit?.uuid || '',
      isWithdraw: false,
      onSubmit,
      isLoading: isCreating || isRequesting,
      wallets,
      initialValues: formData as UnknownType,
      title: t('replanishment.requestReplanishment'),
      rootUnitId: unit?.rootUnitId as UnknownType,
    });
  };

  useEffect(() => {
    if (formWithdraw.isOpened()) {
      formWithdraw.updateDrawer({
        isLoading: isCreating || isRequesting,
      });
    }
  }, [isCreating, isRequesting]);

  useEffect(() => {
    if (uploadPOForm.isOpened()) {
      uploadPOForm.updateDrawer({
        isLoading: isMassWithdrawLoading,
      });
    }
  }, [isMassWithdrawLoading]);

  return (
    <>
      {checkPermissions([Permission.CLIENT_PAYMENT_ORDER_CREATE]) && (
        <Button
          type="default"
          withoutWaveEffect
          disabled={disabled}
          onClick={createPayment}
          suffixIcon={<PlusOutlined />}
          className={clsx(styles.headerButton, { [styles.disabled]: isLoading })}
        >
          {t('processing.paymentOrders.createPayment')}
        </Button>
      )}
      <ActionDropdown
        isLoading={isLoading}
        wallets={wallets}
        onRequestReplanishment={requestReplen}
        onMassPayout={uploadPayments}
        disabled={disabled}
      />
    </>
  );
};

export default Actions;
