import React, {
  ReactElement,
  useCallback,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button, Radio, RadioChangeEvent, Space, Typography,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import clsx from 'clsx';
import _size from 'lodash/size';
import qs from 'qs';
import { FilterField } from './types/FilterField';
import { FilterRelation } from './types/FilterRelation';
import { FileExtension } from '../../hooks/useExportFile';
import { convertFiltersToObject, stringifyFilters } from './utils';
import {
  ControlButtons,
  DisplayOf,
  FilterInput,
  SelectItem,
  SelectWindow,
} from './components';
import {
  RedoOutlined, SearchOutlined, SlidersOutlined, UpOutlined,
} from '@ant-design/icons';
import styles from './Filters.module.scss';

export type FiltersProps<T> = {
  onReset?: () => void;
  onExport?: (type: FileExtension) => void;
  onRefresh?: () => Promise<void>;
  displayOf?: DisplayOf;
  columns?: ColumnsType<T>;
  alwaysDisplayedColumns?: string[];
  displayedColumns?: string[];
  fields?: Map<string, FilterField>;
  onSubmit?: (data: Record<string, unknown>) => void;
  prefixControls?: ReactElement | ReactElement[] | null;
  postfixControls?: ReactElement | ReactElement[] | null;
  updateDisplayedColumns?: (columnKeys: string[]) => void;
  hideResetFilterButton?: boolean;
  initialData?: {
    filters?: Record<string, unknown>;
    filterRelation?: FilterRelation;
  };
  hideOrRelation?: boolean;
};

const Filters = <T extends unknown>({
  updateDisplayedColumns,
  alwaysDisplayedColumns,
  hideResetFilterButton,
  displayedColumns,
  postfixControls,
  prefixControls,
  hideOrRelation,
  initialData,
  displayOf,
  onRefresh,
  onExport,
  onSubmit,
  onReset,
  columns,
  fields = new Map(),
}: FiltersProps<T>): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [activatedFiltersCount, setActivatedFiltersCount] = useState<number>(
    _size(initialData?.filters) || 0,
  );
  const [error, setError] = useState<string | null>(null);
  const [filtersCollapsed, setFiltersCollapsed] = useState(true);
  const [filters, setFilters] = useState<Record<string, unknown>>(initialData?.filters || {});
  const [filterRelation, setFilterRelation] = useState<FilterRelation>(
    initialData?.filterRelation || FilterRelation.OR,
  );
  const [visibleFields, setVisibleFields] = useState<Record<string, boolean>>(
    [...fields].reduce((acc, [key, filterField]) => ({
      ...acc,
      [key]: Boolean(
        filterField.alwaysVisible || (initialData?.filters && key in initialData.filters),
      ),
    }), {}),
  );

  const collapseFiltersBlock = () => setFiltersCollapsed((prevValue) => !prevValue);

  const clearFilters = useCallback(() => {
    setActivatedFiltersCount(0);
    setError(null);
    setFilters({});

    if (onReset) {
      onReset();
    }

    const {
      filters: filtersObject,
      filterRelation: filterRelationField,
      ...rest
    } = qs.parse(location.search, { ignoreQueryPrefix: true });

    navigate({
      pathname: location.pathname,
      search: qs.stringify(rest),
    }, { replace: true });
  }, [navigate, location.pathname, location.search, onReset]);

  const onRelationChange = (event: RadioChangeEvent) => {
    setError(null);
    setFilterRelation(event.target.value);
  };

  const onDeleteFilterClick = useCallback((filterKey: string) => {
    setVisibleFields((prev) => ({ ...prev, [filterKey]: !prev[filterKey] }));
    setFilters((prev) => {
      const newFilters = { ...prev };

      delete newFilters[filterKey];

      return newFilters;
    });
    setError(null);
  }, [setVisibleFields]);

  const changeFilterFieldVisibility = useCallback((item: SelectItem, status: boolean) => {
    const { key } = item;

    setVisibleFields((prev) => ({ ...prev, [key]: status }));
    setError(null);
  }, [setVisibleFields]);

  const onFieldChange = useCallback((fieldKey: string, value: unknown) => {
    setFilters((prevState) => {
      const state = { ...prevState };

      if (value !== '') {
        return { ...prevState, [fieldKey]: value };
      }

      delete state[fieldKey];

      return state;
    });

    setError(null);
  }, [setFilters]);

  const renderFilterField = useCallback(
    ([key, filterField]: [string, FilterField]) => (visibleFields[key] ? (
      <FilterInput
        key={key}
        filterKey={key}
        filters={filters}
        value={filters[key]}
        onChange={onFieldChange}
        filterField={filterField}
        onDeleteClick={onDeleteFilterClick}
      />
    ) : null
    ), [filters, visibleFields, onFieldChange, onDeleteFilterClick],
  );

  const submit = useCallback(() => {
    const filledFilters = Object.keys(filters).filter((key) => visibleFields[key]);

    if (filledFilters.length === 0) {
      return setError(t('pleaseFillTheFields'));
    }

    const search: Record<string, unknown> = convertFiltersToObject(
      fields,
      filterRelation,
      filledFilters.reduce((acc, key) => ({ ...acc, [key]: filters[key] }), {}),
    );

    setActivatedFiltersCount(filledFilters.length);
    setFiltersCollapsed(true);

    const {
      filters: filtersObject,
      ...rest
    } = qs.parse(location.search, { ignoreQueryPrefix: true });

    navigate({
      pathname: location.pathname,
      search: qs.stringify({
        ...rest,
        page: 1,
        filterRelation,
        filters: stringifyFilters(fields, filters),
      }),
    }, { replace: true });

    return onSubmit && onSubmit(search);
  }, [filters, fields, filterRelation, location.search, location.pathname, navigate, onSubmit, visibleFields, t]);

  const areThereVisibleFilters = Object.values(visibleFields).includes(true);

  return (
    <section className={styles.container}>
      <div className={styles.controlButtons}>
        <ControlButtons
          columns={columns}
          displayOf={displayOf}
          onExportClick={onExport}
          onRefreshClick={onRefresh}
          prefixControls={prefixControls}
          postfixControls={postfixControls}
          onResetFilterClick={clearFilters}
          displayedColumns={displayedColumns}
          alwaysDisplayedColumns={alwaysDisplayedColumns}
          activatedFiltersCount={activatedFiltersCount}
          updateDisplayedColumns={updateDisplayedColumns}
          hideResetFilterButton={hideResetFilterButton || !areThereVisibleFilters}
          onAddFilterClick={onSubmit && collapseFiltersBlock}
        />
      </div>
      <Space
        size={30}
        direction="vertical"
        className={clsx(styles.filters, { [styles.hidden]: filtersCollapsed })}
      >
        <div>
          <Button
            className={styles.collapseButton}
            onClick={collapseFiltersBlock}
            icon={<UpOutlined />}
            ghost
          >
            {t('collapsePanel')}
          </Button>
        </div>
        <Space className={styles.filterButtons}>
          {onSubmit ? (
            <SelectWindow
              items={[...fields].map(([key, { label, alwaysVisible }]) => ({
                key,
                label,
                disabled: alwaysVisible,
                selected: visibleFields[key],
              }))}
              onItemClick={changeFilterFieldVisibility}
            >
              <Button
                className={clsx(styles.button, styles.selectButton)}
                icon={<SlidersOutlined />}
                size="large"
              >
                <span>{t('selectFields')}</span>
              </Button>
            </SelectWindow>
          ) : <div />}
          {onSubmit && areThereVisibleFilters && (
            <Button
              className={clsx(styles.button, styles.resetFiltersButton)}
              onClick={clearFilters}
              icon={<RedoOutlined />}
              size="large"
              danger
            />
          )}
        </Space>
        <Space className={styles.fields} size={15}>
          {[...fields].map(renderFilterField)}
        </Space>
        <div>
          <Button
            className={clsx(styles.button, styles.searchButton)}
            icon={<SearchOutlined />}
            hidden={!areThereVisibleFilters}
            onClick={submit}
            type="primary"
            size="large"
          >
            {t('search')}
          </Button>
          <span className={styles.relationFields}>{`${t('relationFields')}:`}</span>
          <Radio.Group onChange={onRelationChange} value={filterRelation}>
            <Radio value={FilterRelation.OR} disabled={hideOrRelation}>{t('or')}</Radio>
            <Radio value={FilterRelation.AND}>{t('and')}</Radio>
          </Radio.Group>
          <Typography.Paragraph type="danger" className={styles.error}>{error}</Typography.Paragraph>
        </div>
      </Space>
    </section>
  );
};

export default Filters;
