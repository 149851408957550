import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Segmented, Spin, Tooltip } from 'antd';
import { NestedUnitType } from 'interfaces/IUnit';
import { Button, CountUp, CurrencySelect, StatisticsRelationLink } from 'components';
import { Actions } from 'components/WalletOverview/components';
import { ProgressBar } from './components';
import { InfoCircleOutlined, LineChartOutlined } from '@ant-design/icons';
import styles from './TotalBalance.module.scss';

export type TotalBalancePropsType<T extends string | number> = {
  unit: NestedUnitType | undefined;
  currency: string;
  setCurrency: (value: string) => void;
  balance: number;
  title: string | React.ReactNode;
  options: { value: string | number; label: string }[];
  onToggle: (value: T) => void;
  checkedOption: T;
  isLoading?: boolean;
  reducedBalances: Record<string, {
    original: number;
    converted: number;
  }>
  onRefresh: () => Promise<void>;
};

const currencyOptions = [
  { value: 'USDT', label: 'USDT' },
  { value: 'USDC', label: 'USDC' },
  // { value: 'BUSD', label: 'BUSD' },
  { value: 'BNB', label: 'BNB' },
  { value: 'DAI', label: 'DAI' },
  { value: 'TRX', label: 'TRX' },
  { value: 'BTC', label: 'BTC' },
  { value: 'ETH', label: 'ETH' },
  { value: 'USD', label: 'USD' },
  { value: 'EUR', label: 'EUR' },
  { value: 'UAH', label: 'UAH' },
  { value: 'GBP', label: 'GBP' },
  { value: 'ILS', label: 'ILS' },
  { value: 'AED', label: 'AED' },
];

const colorsByCurrency = {
  'USDT': '#26A17B',
  'USDC': '#2775CA',
  'BTC': '#F7931A',
  'TRX': '#EF0027',
  'ETH': '#3C3C3D',
  'DAI': '#F4B731',
  'BNB': '#F1CD78',
};

const prepareProgressBarData = (reducedBalances: Record<string, { original: number, converted: number }>, totalBalance: number) => {
  return Object.entries(reducedBalances).map(([key, value]) => {
    const color = colorsByCurrency[key as keyof typeof colorsByCurrency] || '#000';
    return ({
      color,
      value: (value.converted / totalBalance) * 100,
      tooltip: {
        currency: key,
        value: value.original,
      },
    });
  });
};

export const TotalBalance = <T extends string | number>({
  unit,
  setCurrency,
  currency,
  balance,
  title,
  options,
  onToggle,
  checkedOption,
  isLoading,
  reducedBalances,
  onRefresh,
}: TotalBalancePropsType<T>) => {
  const { t } = useTranslation();

  const progressBarData = useMemo(() => {
    return prepareProgressBarData(reducedBalances, balance);
  }, [balance, reducedBalances]);

  return (
    <section className={styles.wrapper}>
      <div className={styles.groupBalance}>
        <span className={styles.title}>
          {title}
        </span>

        <Segmented
          key={checkedOption} // to skip animation
          className={styles.segmented}
          options={options}
          onChange={(value) => onToggle(value as T)}
          value={checkedOption}
        />
        <Tooltip title={t('whatIsGroupAndUnit')}>
          <InfoCircleOutlined />
        </Tooltip>

        {(unit?.rootUnitId || unit?.id) && (
          <StatisticsRelationLink rootUnitId={unit.rootUnitId} unitId={unit.id} className={styles.link}>
            <Button
              type="link"
              withoutBgShadow
              withoutWaveEffect
              style={{ padding: 0 }}
              disabled={!unit?.isActive}
              textSize="17px"
              suffixIcon={<LineChartOutlined style={{ fontSize: 16 }} />}
            />
          </StatisticsRelationLink>
        )}
      </div>

      <div className={styles.info}>
        {currency && !!currencyOptions.length ? (
          <div className={styles.balanceWrapper}>
            {isLoading ? (
              <Spin />
            ) : (
              <CountUp
                start={0}
                end={Number(balance)}
                currency={currency}
                className={styles.balance}
                isRoundDown
              />
            )}
            <CurrencySelect
              wrapperWidth={100}
              currency={currency}
              onSelect={setCurrency}
              options={currencyOptions}
            />
          </div>
        ) : (
          <span className={styles.balance}>0,00</span>
        )}
      </div>
      <ProgressBar segments={progressBarData} />
      <div className={styles.actionsWrapper}>
        {!isLoading && (
          <Actions
            unit={unit}
            onRefresh={onRefresh}
            disabled={!unit?.isActive || isLoading}
            isLoading={isLoading}
          />
        )}
      </div>
    </section>
  );
};
